import React from "react";
import { Link } from "gatsby";
import damixaMobileCommercial from "../../images/damixa-commercial-bg--mobile@1x.jpg";
import damixaCommercial from "../../images/damixa-commercial-bg@1x.jpg";
import {t} from "i18next";

const DamixaCommercial = () => {
  return (
    <section className="commercial commercial--damixa">
      <div className="commercial__image-wrapper">
        <picture>
          <source media="(max-width: 991px)" srcSet={damixaMobileCommercial} />
          <img src={damixaCommercial} alt="Вдохновляющий дизайн" />
        </picture>
      </div>
      <div className="commercial__wrapper wrapper">
        <div className="commercial__text-wrapper commercial__text-wrapper--black">
          <p>
            {t('lead.damixa.featured-upper-text')}
          </p>
          <p className="commercial__small-text">
            {t('lead.damixa.featured-middle-text')}
          </p>
          <p>
            {t('lead.damixa.featured-bottom-text')}
          </p>
          <Link
            to="https://damixa.ru/"
            className="link-button commercial__link-button"
          >
            {t('lead.damixa.featured-text-link')}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default DamixaCommercial;
